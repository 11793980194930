import ReactPlayerLoader from '@brightcove/react-player-loader'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import styles from './video.module.scss'

import Seo from '@/components/general/seo'
import Layout from '@/components/layout/layout'

import { Navigation } from '@/components/video/navigation'
import ViewingEnv from '@/components/video/viewing-env'
import { ACCOUNT_ID } from '@/lib/constants'
import { BreadcrumbContext } from '@/types/breadcrumb'

const Page: React.FC<BreadcrumbContext> = ({ pageContext }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext
  return (
    <Layout>
      <Seo title="双極II型障害における認知機能障害の管理" />
      <Helmet>
        {/* <!-- Ptengine Tag --> */}
        <script src="https://js.ptengine.jp/67a5keif.js"></script>
        {/* End Ptengine Tag */}
      </Helmet>
      <div className={styles.wrap}>
        <h1 className={styles.title}>
          第10回 双極II型障害における認知機能障害の管理
        </h1>
        <ReactPlayerLoader
          attrs={{ className: styles.video }}
          accountId={ACCOUNT_ID}
          videoId="6319151428112"
          playerId="hM1SBfBel"
          embedOptions={{ responsive: 'aspectRatio' }}
        />
        <div className={styles.videoBody}>
          <div className={styles.speaker}>
            <p>
              <span>ファシリテーター</span>
              <span>Lakshmi N. Yatham教授 (ブリティッシュコロンビア大学)</span>
            </p>
            <p>
              <span>エキスパート</span>
              <span>Glenda MacQueen教授 (カルガリー大学)</span>
            </p>
          </div>
          <Navigation prev="11" next="13" />
          <div className={styles.paragraph}>
            <p>
              双極II型障害の認知機能障害の特徴とその対処法などを、日本語字幕付き対談動画で学ぶことができます。
              <br />
            </p>
            <div>
              <span>＜学びのポイント＞</span>
              <ul>
                <li>双極II型障害患者における認知機能障害の特徴</li>
                <li>認知機能障害の診療、治療において考慮すべきポイント</li>
                <li>認知機能障害に有用なアプローチ</li>
              </ul>
            </div>
            <p>大日本住友製薬（現：住友ファーマ株式会社）</p>
          </div>
          <ViewingEnv />
          <Breadcrumb
            crumbs={crumbs}
            crumbSeparator=" > "
            crumbLabel={'双極II型障害における認知機能障害の管理'}
          />
        </div>
      </div>
    </Layout>
  )
}

export default Page
